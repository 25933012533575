<template>
  <div>
    <b-card>
      <AgGrid
        ref="ISFGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-b-tooltip.hover="'Create a New entry'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewISF()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New ISF</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateISFModal"
      ref="CreateISF"
      title="New ISF"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Customer"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Customer:</b></i></label>
                  <v-select
                    id="CustomerOption"
                    v-model="header.CustomerId"
                    :options="CustomerOptions"
                    label="name"
                    placeholder="Select a Customer"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="ISF Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>ISF Type:</b></i></label>
                  <v-select
                    id="ISFSubmissionOption"
                    v-model="header.SubmissionType"
                    :options="ISFSubmissionOptions"
                    label="text"
                    placeholder="Select the submission type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Shipment Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Shipment Type:</b></i></label>
                  <v-select
                    id="ShipmentTypeOption"
                    v-model="header.ShipmentType"
                    :options="ShipmentTypeOptions"
                    label="text"
                    placeholder="Select the shipment type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="SubShipment Type"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>SubShipment Type:</b></i></label>
                  <v-select
                    id="ShipmentSubTypeOptions"
                    v-model="header.SubShipmentType"
                    :options="ShipmentSubTypeOptions"
                    label="text"
                    placeholder="Select the shipment type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="MOT"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MOT:</b></i></label>
                  <v-select
                    id="MOTOption"
                    v-model="header.MOT"
                    :options="MOTOptions"
                    label="text"
                    placeholder="Select the MOT"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewISF()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIISFViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      aggrid: [],
      isfId: '',
      CustomerOptions: [],
      CountryOptions: [],
      ISFActionOption: [
        { text: 'Action: Add', value: 'A' },
        { text: 'Action: Replace', value: 'R' },

      ],
      ISFSubmissionOptions: [
        { text: 'ISF-10', value: '1' },
        { text: 'ISF-5', value: '2' },
        { text: 'ISF-5 to 10', value: '3' },
        { text: 'ISF-10 to 5', value: '4' },
      ],
      ISFActionReasonOptions: [
        { text: 'Complete Transaction', value: 'CT' },
        { text: 'Fleible Range', value: 'FR' },
        { text: 'Flexible Timing', value: 'FT' },
        { text: 'Flexible Range and Timing', value: 'FX' },
      ],
      MOTOptions: [
        { text: 'Vessel, Non-container (10)', value: '10' },
        { text: 'Vessel, Container (11)', value: '11' },
      ],
      ShipmentTypeOptions: [
        { text: 'Standard or Regular Filings', value: '01' },
        { text: 'To Order Shipments', value: '02' },
        { text: 'Household Goods / Personal Effects (HHG / PE)', value: '03' },
        { text: 'Military, Government', value: '04' },
        { text: 'Diplomatic Shipment', value: '05' },
        { text: 'Carnet', value: '06' },
        { text: 'US Goods Returned', value: '07' },
        { text: 'FTZ Shipments', value: '08' },
        { text: 'International Mail Shipments', value: '09' },
        { text: 'Outer Continental Shelf Shipments', value: '10' },
        { text: 'Informal', value: '11' },

      ],
      ShipmentSubTypeOptions: [
        { text: 'Section 321', value: '01' },
        { text: 'Informal Shipments', value: '02' },
        { text: 'General Note 3(e) Shipments', value: '03' },
      ],
      header: {
        CustomerId: '',
        SubmissionType: null,
        MOT: null,
        ShipmentType: null,
        SubShipmentType: null,

      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ISF')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'isfId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Active', field: 'active', minWidth: 100 },
      { headerName: 'Status', field: 'status', minWidth: 100 },
      {
        headerName: 'Customer', field: 'customer', minWidth: 125, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'ISF #', field: 'isfNumber', minWidth: 155, sort: 'desc', enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Type', field: 'isfType', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Action', field: 'actionReasonCode', minWidth: 175, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'MOT', field: 'mot', minWidth: 90, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'SCAC', field: 'scac', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'MBOL', field: 'mbol', minWidth: 100,
      },
      {
        headerName: 'Est. Qty', field: 'estimatedQty', maxWidth: 120,
      },
      {
        headerName: 'Est. Value', field: 'estimatedValue', maxWidth: 120,
      },
      {
        headerName: 'HBOLs', field: 'totalHBOL', maxWidth: 120,
      },
      {
        headerName: 'Containers', field: 'totalContainers', maxWidth: 120,
      },
      {
        headerName: 'Edit Log',
        marryChildren: true,
        children: [
          {
            headerName: 'Updated By', field: 'lastUpdatedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Updated On',
            field: 'lastUpdatedOn',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
          {
            headerName: 'Added By', columnGroupShow: 'open', field: 'addedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Added On',
            columnGroupShow: 'open',
            field: 'addedDate',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
        ],
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ISFGrid.saveState('ISF') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ISFGrid.saveState('ISF') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ISFGrid.saveState('ISF') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ISFGrid.saveState('ISF') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(isfId) {
      this.isfId = isfId
      this.$router.push(`/abi/isf/detail/${isfId}`)
    },
    DoubleClickOpenPopup(params) {
      this.isfId = params.data.isfId
      this.$router.push(`/abi/isf/detail/${params.data.isfId}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadISF(), this.LoadCustomerList(), this.LoadCountryList()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadISF() {
      axios.get('/isf/list')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ISFGrid.ResetColumns()
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abi/customer/codes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    CreateNewISF() {
      Object.assign(this.$data.header, this.$options.data.call(this))

      this.$refs.CreateISF.show()
    },
    SaveNewISF() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ISFModel = {
            CustomerId: this.header.CustomerId.id,
            IsActive: true,
            Status: 'Open',
            SubmissionType: this.header.SubmissionType.value,
            MOT: this.header.MOT.value,
            ShipmentType: this.header.ShipmentType.value,
            SubShipmentType: this.CheckNull(this.header.SubShipmentType) ? this.header.SubShipmentType.value : '',
            ActionType: 'A',
            EstimatedValue: 0,
            EstimatedQty: 0,
            EstimatedWeight: 0,
          }

          axios.post('/isf/create', ISFModel)
            .then(response => {
              this.LoadingEnd()
              this.$refs.CreateISF.hide()
              this.DisplaySuccess('ISF data has been saved.').then(() => {
                this.$router.push(`/abi/isf/detail/${response.data}`)
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateISF.hide()
              this.DisplayError('New ISF cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New ISF cannot be saved.', 'Please check data requirements.')
        }
      })
    },
  },
}
</script>
